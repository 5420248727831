import styled  from "styled-components";


export const Wrapper = styled.div`
    /* position: absolute; */
     height:50px;
    display: inline-block;
    position: absolute;
 
    
    /* background-color:green; */
    .ant-dropdown-link{
        position: absolute;
        width:100px;
        left:40px;
         top:10px;
        color:#333;
        /* background-color: green; */
    }
    .buttons1{
        margin-right:15px !important;

    }
 `