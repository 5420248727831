import styled from "styled-components"

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    .news11{
        padding:100px 0;

    }
    .dragger{
        border: none;
    }
    .icon{
        font-size: 90px;
        /* color: #326A32; */
        color: #888;
    }
    .text_title{
        padding: 50px 0 10px;
    }   
    .galarey{
        margin: 30px 0;
    }
    #VideoPlayer > *{
        aspect-ratio: 16/9;
        width: 100%;
    }
`