export const mediaFlex = {
    m_width:'742px',
    m_direction:"column",
}
export const mediaImage = {
    m_width:"745px",
    m_m_width:'220px',
} 
export const mediaTextFieldExternal = {
    m_width_ext:'500px',
    m_text_align_ext:'center',
}
export const mediaFlexFooter = {
    m_width:'500px',
    m_margin:'0',
    m_direction:'column',
}