import styled from "styled-components"

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    .titlss{
        font-size: 15px;
        font-weight: 700;
        margin: 15px 0;
        color: #000;
    }
    .textb{
        font-size: 13px;
        margin: 0;
    }
    .imgdiv11{
        margin: 15px 20px;
        display: flex;
        justify-content: space-around;
    }
    .links{
        color:green
    }
`