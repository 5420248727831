import styled from "styled-components"

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    font-family: Montserrat !important;
     
    @media (max-width:600px){
        .css-19kzrtu{
            padding:0;
        }
        .border-div{
            min-width:120px;
            /* padding:0 2px; */
        }
    }
`