import React from 'react'
import img02 from '../../assets/img/about/404.svg'
import './style.css'

const Index = () => {
    return (
        <div>
            <div className="imgss">
            <img className="img1" src={img02} alt="404"/>
            </div>
        </div>
    )
}

export default Index
