import styled from "styled-components"
import {mainGreen} from "../../../../styles/global/colors"
export const Wrapper = styled.form`
    width: 100%;
    height: auto;
    .btns{
        margin-right:0px !important
    }
    .fCSNeF,.bqWjhY{
        padding:9px 13px !important;
    }
    .icon{
        position: relative;
        top:2px;
        
    }
    .sc-hkwmXC.bNRZXE{
        padding: 11px 4px !important;
    }
    .edit_div{
        /* background-color:red; */
        display: inline-block;
        padding:5px 7px;
        position: relative;
        top:30px; left: 15px;
        border-radius: 4px;
        border:1px solid ${mainGreen};
        cursor:pointer;
    }
    .icon_edit{
        font-size:20px;
        color:${mainGreen};
    }
    .ant-picker.SwitchablePicker_style.ant-picker-focused{
        box-shadow:none;
    }
    .btnss{
        margin: 0px !important;
        margin-top:10px !important
    }
`