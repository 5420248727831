export const media = {
    m_width:"533px",
    m_m_width:"300px",
    m_m_text_align:"center",
}
export const mediaFlexButtons = {
    m_width:"455px",
    m_m_width:'300px',
    m_direction:"column",
}
export const mediaBtn = {
    m_width_btn:"436px",
    m_m_width_btn:"300px",
    m_m_font_btn:"14px",
    m_m_padding:"12px 28px"
}
    
export const mediaTextField = {
    m_width:"1045px",
    m_font_size:"24px",
}
export const mediaTextFieldSec = {
    m_width_sec:"500px",
    m_font_size_sec:"20px",
}
export const mediaContainer = {
    m_width:"500px",
    m_padding:"10px 0",
}