import styled from "styled-components"

export const Wrapper = styled.div`
    padding:10px;
    
    .title-header-checkbox{
        color:#000;
        margin:10px 0;
    }
    .check{
        margin:10px 0;
        color:green;
    }
    
`