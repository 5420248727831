import styled from "styled-components"

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    
    .fCSNeF,.bqWjhY{
        padding:9px 13px !important;
    }
    .icon{
        position: relative;
        top:2px;
    }
    .sc-hkwmXC.bNRZXE{
        padding: 11px 4px !important;
    }
    .sc-dIUeWJ.eobrpm{
        margin:0 !important;
    }
    .div22{
        text-align:right !important;
    }
    .dds{
        float:right
    }

`