import styled from "styled-components"

export const Wrapper = styled.div`
    width: 100%;
    height: auto;

    .wrap_class{
        display:flex;
        align-items: center;
        p{
            margin:0;
            position:relative;
            top:10px; left:10px;
        }
    }
    .wrap_class_inner{
        position: relative;
        top:13px; left:2px;
    }
`