import styled from "styled-components"

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    .wrap_class_inner{
        width: 100%;
    }
     .text1{
        margin: 20px 0;
        text-align:center;
        font-weight: bold;
    }
`