import styled from "styled-components"
export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    .btninfo{
        display:flex !important;
        justify-content:flex-end !important;
    }
    .btninfo2{
        margin-right:20px ;
    }
    
    
`