export const colourOptions = [
    {
        label: ' #Biznes',
        value: 'tag1'
    },
    {
        label: "#Sayoxat", 
        value: "tag2"
    },
    {
        label: "#Siyosiy",
        value: "tag3"
    },
    {
        label: "#Rasmiy", 
        value: "tag4"
    }
]
export const colourOptionsApi = {
    Biznes:"tag1",
    Sayoxat:'tag2',
    Siyosiy:'tag3',
    Rasmiy:'tag4'
}