export const defaultObj ={
  trainings:[],
    middle_name: "",
    first_name: "",
    last_name: "",
    birthday: "",
    bio: "",
    country: 1,
    city: null,
    gender: null,
    image: "",
    imageFile: null,
    
  }