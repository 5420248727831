import styled from "styled-components"
export const Wrapper = styled.div`
    position: relative;
    padding-left: 10px;
    margin:10px 0;
    .MuiIconButton-label{
        color: #326A32;
    }
    .title-header-radio{
    font-weight:600;
        margin:10px 0;
        justify-content: center;
    }
    span{     
        font-size:16px;
        font-family: Montserrat;
    }
    .first{
        margin-right: 60px;
    }
    .double_check{
        /* display: inline-block; */
        /* position:relative; */
        /* top:20px; left:-30px; */
    }
`