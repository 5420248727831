import React, {useState} from 'react'
import {Container} from "../../../styles/container/index.style"
import Button from "../../../components/atom/button"
import {useHistory} from "react-router-dom"
import Select from "../../../components/atom/select"
import AuthInput from "../../../components/atom/auth.input"
import { Grid } from '@mui/material'
import {mediaContainer, mediaContainerSec, mediaBtn} from "./_media"
import {useSelector, useDispatch} from "react-redux"
import {post_auth_reg_action} from "../../../redux/actions"
import {validator} from "../../../custom/validator"
import {WrapperReg} from "./reg.style";
import toast from "react-hot-toast";
import { useTranslation } from 'react-i18next';

const Index = () => {
    const appNoToken = JSON.parse(localStorage.getItem('appNoToken')) || false
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const selector = useSelector(prev=>prev.post_auth_reg_reducer)
    const [loader, setLoader] = useState(false)
    const [stateName, setStateName] = useState('')
    const [stateLast, setStateLast] = useState('')
    const [select, setSelect] = useState(null)
    const [stateEmail, setStateEmail] = useState('')
    const [statePassword, setStatePassword] = useState('')
    const [statePasswordRecover, setStatePasswordRecover] = useState('')
    const [nameV, setNameV] = useState({error:false, errorText:''})
    const [lastV, setLastV] = useState({error:false, errorText:''})
    const [emailV, setEmailV] = useState({error:false, errorText:''})
    const [selectV, setSelectV] = useState({error:false, errorText:''})
    const [passwordV, setPasswordV] = useState({error:false, errorText:''})
    const [passwordRecoverV, setPasswordRecoverV] = useState({error:false, errorText:''})
    
    const options = [
        { value: 'simple_user', label: `${t("auth_registr.user")}` },
        { value: 'gid', label: `${t("auth_registr.gid")}` },
        { value: 'translator', label: `${t("auth_registr.tarjimon")}` },
        { value: 'writer', label: `${t("auth_registr.yozmaT")}` },
      ];
    
    const validatorFunction = () => {
        let v_name = validator('min', stateName, 3,  t("auth_registr.ismKamida"), '', setNameV, nameV)
        let v_last = validator('min', stateLast, 3,  t("auth_registr.FamiliyaKamida"), '', setLastV, lastV)
        let v_email = validator('email', stateEmail, 3, t("auth_registr.EmailXato"), '', setEmailV, emailV)
        let v_select = validator('select', select, 3, t("auth_registr.FoydalanuvchiTuri"), '', setSelectV,selectV)
        let v_pass = validator('min', statePassword, 8, t("auth_registr.KamidaBel"), '', setPasswordV,passwordV)
        let v_pass_r = validator('min', statePasswordRecover, 8, t("auth_registr.ParolXato"), '', setPasswordRecoverV, passwordRecoverV)
        
        if(v_name === '' && v_last === '' && v_email=== '' && v_select==='' && v_pass==='' && v_pass_r ==='') return true
        else return false
}
  
    const onSubmit = (e) => {
        e.preventDefault();
        let objectPost = {
            first_name: stateName,
            last_name: stateLast,
            password: statePassword,
            password2: statePasswordRecover,
            role: select?.value || null,
            username: stateEmail
        }
        // console.log(objectPost)
        setLoader(true)
        let check = validatorFunction()
         let check_password = statePasswordRecover===statePassword
        if((statePasswordRecover !== statePassword) || !check) {
            setPasswordRecoverV({...passwordRecoverV, errorText: t("auth_registr.ParolXato")})
            setLoader(false)
        }
      
        if(check && check_password) {
            localStorage.setItem('email',stateEmail)
            dispatch(post_auth_reg_action(objectPost))
        }
    }
  

    React.useEffect(()=>{
        if(appNoToken){
            setSelect({ value: 'simple_user', label: `${t("auth_registr.user")}` })
        }
    },[])
    
    React.useEffect(()=>{
        if(selector?.status){
            setLoader(false)
            if(selector.status === 200) return history.push('/auth/verify')
            if(selector.status === 400) return toast.error('Ushbu emailda foydalanuvchi mavjud')
        }
    },[selector])
  
    React.useMemo(()=>{
        if(stateName.length>3) setNameV({...nameV,errorText:''})
        if(stateLast.length>3) setLastV({...lastV,errorText:''})
        if(stateEmail.includes('@')) setEmailV({...emailV,errorText:''})
        if(select) setSelectV({...selectV,errorText:''})
        if(statePassword.length>8) setPasswordV({...passwordV,errorText:''})
        if(statePasswordRecover.length>8) setPasswordRecoverV({...passwordRecoverV,errorText:''})
    },[stateName, stateEmail, statePassword, statePasswordRecover, stateLast, select])
    return (
      
        <WrapperReg>
            <form onSubmit={onSubmit}>
                <Container width="100%" {...mediaContainer}>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid className="grid_item" item xs={12} md={6}> 
                            <AuthInput 
                                errorText={nameV.errorText} 
                                setState={setStateName}
                                title= {t("auth_registr.ismingiz")}
                                width="100%"
                            />
                        </Grid>
                        <Grid className="grid_item" item xs={12} md={6}> 
                            <AuthInput 
                                errorText={lastV.errorText} 
                                setState={setStateLast} 
                                title= {t("auth_registr.familya2")}
                                width="100%"
                            />
                        </Grid>
                    </Grid>
                </Container>
                <Container {...mediaContainer}>
                    <Select isDisabled={appNoToken} paddingX="9px 4.5px" pcolor errorText={selectV.errorText} options={options} setState={setSelect} placeholder={t("auth_registr.user")}/>
                </Container>
                <Container  {...mediaContainer} >
                    <AuthInput errorText={emailV.errorText} title={t("auth_registr.email2")} width="100%" setState={setStateEmail}/>
                </Container>
                <Container  {...mediaContainer}>
                    <AuthInput errorText={passwordV.errorText} title={t("auth_registr.pass")} setState={setStatePassword} password={true} width="100%"/>
                </Container>
                <Container  {...mediaContainer}>
                    <AuthInput errorText={passwordRecoverV.errorText} 
                    title={t("auth_registr.repass")}  setState={setStatePasswordRecover} password={true} width="100%"/>
                </Container>
                <Container  {...mediaContainerSec}  className="text-right" margin="20px 0 0">
                    <Button {...mediaBtn} loader={loader}  >{t("auth_registr.submit")}</Button>
                </Container>
            </form>
        </WrapperReg>
    )
}

export default Index
