import styled from "styled-components"
export const Wrapper = styled.div`
width:100%;
text-align:center;
// background:red;
.text_error{
    display:flex;
    flex-direction:column;
    gap:5px;
    color:red;
}
`