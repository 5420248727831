import styled from "styled-components"

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    padding:30px 70px;
    margin-top:30px;
    margin-bottom:80px;
    font-family: Montserrat !important;
    .container4{
        position:relative;
        z-index:10;
    }
    @media (max-width:620px){
        padding:30px 10px;
    }
`