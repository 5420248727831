export const mediaTextField = {
    m_width:"1045px",
    m_font_size:"24px",
}
export const mediaTextFieldSec = {
    m_width_sec:"500px",
    m_font_size_sec:"20px",
}
export const mediaImage = {
    m_width:"533px",
    m_m_width:"300px",
    m_m_text_align:"center",
}
export const mediaImageSecond = {
    m_width:"450px",
    m_m_width:"280px",
    m_m_text_align:"center",
}
export const mediaBtn = {
    m_width_btn:"456px",
    m_m_width_btn:"300px",
    m_m_font_btn:"14px",
    m_m_padding:"12px 28px"
}
