import styled from "styled-components"
export const Wrapper = styled.div`
    padding-left: 10px;
    margin:10px 0;
    .MuiIconButton-label{
        color: #326A32;
    }
    .title-header-radio{
        font-weight:600;
        margin:10px 0;
    }
    span{
        font-size:16px;
        font-family: Montserrat;
    }

`