import styled from "styled-components"
export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    padding: 10px 55px;
     
    .pagination{
        text-align: center;
        padding: 20px 0;
    }
    .sc-fKFxtB.cNPZrT{
        margin:0 auto;
    }
    @media(min-width:1367px){
            width: 1450px;
            margin: 0 auto;
    }  
    @media (max-width:959px){
        .media_grid_flex{
            flex-direction: column;
        }
    }
    @media (max-width:450px){
        padding: 10px 20px;
    }
    @media (max-width: 1180px){
        .ImagesChoose, .ImagesChoose img{
            width: 100% !important;
        }
    }
    
`