export const mediaContainer = {
    m_width:"534px",
    m_padding:"4px",
    m_margin:"5px 0"
}
export const mediaContainerSec = {
    m_width:"534px",
    m_padding:"4px",
    m_text_align:"center",
    // m_margin:"5px 0"
}
export const mediaBtn = {
    m_width_btn:"456px",
    m_m_width_btn:"260px",
    m_m_font_btn:"14px",
    m_m_padding:"12px 28px",
    // margin:"10px 0"
}


export const mediaBtnAuth = {
    m_width_btn:"456px",
    m_m_width_btn:"260px",
    m_m_font_btn:"14px",
    m_m_padding:"12px 28px"
}
export const mediaContainerSecAuth = {
    m_width:"534px",
    m_padding:"4px",
    m_text_align:"center"
}