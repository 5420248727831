export const mediaContainer = {
    m_width: "600px",
    m_direction: "column",
}
export const mediaContainerSecond = {
    m_width: "600px",
    m_margin: '25px 0 0 0'
}
export const mediaContainerSecondText = {
    m_width: "600px",
    m_margin: '15px auto 0',
    m_display: "inline-block",
    m_m_width: '280px'
}
export const mediaContainerPadding = {
    m_width: "600px",
    m_padding: '0 5px'
}