import styled from "styled-components"

export const Wrapper = styled.div`
    width: 100%;
    min-height: 80vh;
`
export const ContainerVerify = styled.div`
    width:500px;
    height: auto;
    padding:10px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);

    .error_text{
        position: relative;
        font-size: 11px;
        bottom: -14px;
        font-style: italic;
        color: red;
        width: 100%;
        /* background: red; */
        top: -56px;
        left: -184px;
    }

`
