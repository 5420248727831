import styled from "styled-components"

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    .text1{
        margin: 20px 0;
        text-align:center;
        font-weight: bold;
    }
`