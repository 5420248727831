import styled from "styled-components"

export const Wrapper = styled.div`
    width: 100%;
    min-height: 80vh;
    `
export const ContainerVerify = styled.div`
    width:500px;
    .input-container{
       margin-bottom:10px !important;
    }
    height: auto;
    padding:10px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
`
