import styled from "styled-components"
export const Wrapper = styled.div`
    display: flex;
    margin:0;
    .selects{
         width: 250px;
    }
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
    
`