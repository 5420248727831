import styled from "styled-components"
export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    .sc-idOiZg.DvRDO{
        position:relative;
        top:52px;
    }
    .fCSNeF,.bqWjhY{
        position: relative;
        /* top:50px; */
        padding:9px 13px !important;
    }
    /* .sc-idOiZg.DvRDO{

    } */
    .icon{
        position: relative;
        top:2px;
    }
    .sc-hkwmXC.bNRZXE{
        padding: 11px 4px !important;
    }
`