export const currency = [
    {value:'UZS', label: 'UZS'},
    {value:'USD', label: 'USD'},
    {value:'RUB', label: 'RUB'},
    {value:'EUR', label: 'EUR'}
]
export const CURRENCY = {
    UZS:"UZS",
    USD:'USD',
    RUB:"RUB",
    EUR:'EUR'
}


 