import styled from "styled-components"
export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    padding:15px;

`
export const TextWrapper = styled.div`
    width: 100%;
    text-aligin:justify;
    .text_box{
        display: inline-block;

    }
    
`